<template>
  <div style="padding-bottom: 200px">
    <RocketLoad
      v-if="isLoading"
      :full="true"
    />
    <form
      @submit.prevent="submitHandler"
      @keyup.enter="handleKeyPress"
      v-if="!isLoading"
    >
      <div class="field col-6 justify-content-start">
        <h2>Cost Detail</h2>
        <div
          v-if="estimate"
          class="font-light"
        >
          <div class="text-link-hover">
            {{ estimate.ce_number }} - {{ estimate.title }}
          </div>
        </div>
      </div>
      <div class="formgrid p-fluid grid justify-content-around align-items-center">
        <Divider class="m-2 mb-5"></Divider>
        <div class="field col-12 sm:col-6">
          <label
            for="name"
            style="color: #687a8d !important"
          >Cost Type</label>
          <span
            v-if="!cost.cost_from_job"
            style="color: #4d7cfe; float: right"
            class="cursor-pointer"
            @click="
              $store.dispatch('setEstimateCostTypeSliderView', 'CostSlider')
            "
          >+ Create New</span>
          <div
            v-if="!cost.cost_from_job"
            class="p-inputgroup"
          >
            <Dropdown
              :options="cost_types"
              dataKey="uid"
              optionLabel="type_name"
              placeholder="Cost Type"
              v-model="cost.item"
              @change="handleCostSelect(cost.item)"
              class="input"
            />
          </div>
          <div v-else>
            {{ cost.item.type_name }}
          </div>
          <div
            class="error-validation-text pt-1"
            v-if="submitted && !$v.cost.item.required"
          >
            Cost type is required
          </div>
        </div>
        <div class="field col-12 sm:col-6">
          <label>Supplier</label>
          <div
            v-if="!cost.cost_from_job"
            class="p-inputgroup"
          >
            <Dropdown
              :options="accountSuppliers"
              dataKey="uid"
              optionLabel="company_name"
              placeholder="Select Supplier"
              class="input"
              v-model="cost.supplier"
            />
          </div>
          <div v-else>
            <span> {{ cost.supplier.company_name }}</span>
          </div>
          <div
            class="error-validation-text pt-1"
            v-if="submitted && !$v.cost.supplier.required"
          >
            Supplier is required
          </div>
        </div>

        <div class="field col-12 sm:col-6">
          <label>Cost Name</label>
          <div
            v-if="!cost.cost_from_job"
            class="p-inputgroup"
          >
            <InputText
              placeHolder="Cost Name (optional)"
              v-model="cost.cost_name"
            />
          </div>
          <div v-else>
            <span v-if="cost.cost_name != null"> {{ cost.cost_name }}</span>
            <span v-else>None</span>
          </div>
        </div>
        <div class="field col-12 sm:col-6">
          <label>Markup Method</label>
          <div
            v-if="!cost.cost_from_job"
            class="p-inputgroup"
          >
            <InputText
              placeHolder="Markup Method"
              v-model="cost.markup_method"
              disabled
            />
          </div>
          <div v-else>
            <span> {{ cost.markup_method }}</span>
          </div>
          <div
            class="error-validation-text pt-1"
            v-if="submitted && !$v.cost.markup_method.required"
          >
            Markup method is required
          </div>
        </div>

        <div class="field col-12">
          <label
            class="font-light"
            for="Description"
          >Description</label>
          <Editor
            ref="editor"
            v-if="!cost.cost_from_job"
            id="Description"
            :autoResize="true"
            editorStyle="height:150px"
            v-model="cost.description"
          >
            <template #toolbar>
              <span class="ql-formats">
                <button class="ql-bold"></button>
                <button class="ql-italic"></button>
                <button class="ql-underline"></button><button class="ql-strike"></button>
                <button
                  class="ql-list"
                  value="ordered"
                ></button>
                <button
                  class="ql-list"
                  value="bullet"
                ></button>
                <button class="ql-align"></button>
                <button class="ql-link"></button>
              </span> </template>
          </Editor>
          <div v-else>
            <span> {{ cost.description }}</span>
          </div>
          <div
            class="error-validation-text pt-1"
            v-if="submitted && !$v.cost.description.required"
          >
            Description is required
          </div>
        </div>
      </div>
      <div>
        <div class="flex">
          <div class="text-xl col">Cost Options</div>

          <div
            v-if="cost.currency && currencyName !== cost.item.currency.name"
            class="col"
            style="text-align: right; color: #9cbff9"
          >
            {{ cost.item.currency.name }} / {{ currencyName }} ({{
              currencyConversion ? currencyConversion.toFixed(6) : null
            }})
          </div>
        </div>
        <template v-if="costOptions.length > 0">
          <div
            class="grid col-12 cursor-pointer"
            style="margin: 0 !important"
            v-for="option in costOptions"
            :key="option.id"
            @mouseenter="mouseenter(option.id)"
            @mouseleave="mouseleave"
          >
            <div class="field col">
              <label>Units</label>
              <div
                v-if="!cost.cost_from_job"
                class="p-inputgroup"
              >
                <InputNumber
                  style="width: 50%"
                  :min="0.01"
                  class="text-center mr-2"
                  v-model="option.units"
                  mode="decimal"
                  :minFractionDigits="2"
                  @input="handleChangeResellAmount(option)"
                  placeholder="Quantity"
                />
              </div>
              <div
                class="p-2 mt-1"
                v-else
              >
                <span> {{ option.units }}</span>
              </div>
            </div>
            <div class="field col">
              <label>Unit cost</label>
              <div
                v-if="!cost.cost_from_job"
                class="p-inputgroup"
              >
                <InputNumber
                  style="width: 50%"
                  :min="0.000001"
                  class="text-center mr-2"
                  mode="decimal"
                  :minFractionDigits="6"
                  v-model="option.unit_cost"
                  @input="handleChangeResellAmount(option)"
                  placeholder="Cost"
                />
              </div>
              <div
                class="p-2 mt-1"
                v-else
              >
                <span> {{ option.unit_cost }}</span>
              </div>
            </div>
            <div class="field col">
              <label>Markup</label>
              <div
                v-if="!cost.cost_from_job"
                class="p-inputgroup"
              >
                <InputNumber
                  :suffix="setSuffix"
                  style="width: 50%"
                  class="text-center mr-2"
                  mode="decimal"
                  :minFractionDigits="2"
                  v-model="option.markup"
                  @input="handleChangeResellAmount(option)"
                  placeholder="Markup"
                />
              </div>
              <div
                class="p-2 mt-1"
                v-else
              >
                <span v-if="cost.markup_method == 'Percentage'">
                  {{ option.markup }}%</span>
                <span
                  class="p-2 mt-1"
                  v-else
                >{{ option.markup }}</span>
              </div>
            </div>
            <div class="field col">
              <label>Unit Price</label>
              <div class="p-inputgroup col text-center align-items-center">
                {{ formatCurrency(option.resell_amount, currencySymbol, 8) }}
              </div>
            </div>
            <div class="field col">
              <label>Total Price</label>
              <div class="p-inputgroup col text-center align-items-center">
                {{
                  formatCurrency(
                    option.resell_amount * option.units,
                    currencySymbol,
                    8
                  )
                }}
              </div>
            </div>
            <div
              class="flex align-items-center"
              v-if="displayOptionIcon == option.id && !cost.cost_from_job"
            >
              <i
                v-if="costOptions.length > 1"
                class="las la-trash i-20 grey"
                @click="deleteOption(option.uid)"
              />
            </div>
            <Divider class="m-2 mb-3"></Divider>
          </div>
        </template>

        <div
          v-if="!cost.cost_from_job"
          class="cursor-pointer pl-3 col-9"
        >
          <Button
            :disabled="costOptions.length == 5"
            class="p-button-text p-button-secondary"
            @click="addOption()"
          >
            + Add option
          </Button>
        </div>
      </div>

      <div class="flex justify-content-between pt-5">
        <div>
          <Button
            v-if="!cost.cost_from_job"
            type="submit"
          ><template v-if="!selectedObject.item">Add</template><template v-else>Update</template> Cost</Button>
        </div>
        <Button
          class="p-button-text p-button-secondary"
          @click="clearSliderContent"
        >
          CANCEL
        </Button>
      </div>
    </form>

    <RocketLoad
      :full="true"
      v-else
    />
  </div>
</template>
    <script>
import {
  fetchAccountCostTypes,
  // fetchTaskTypePricing,
  fetchBillingTiers,
  fetchAccountSuppliers,
  fetchCurrency,
  fetchCurrencyConversion,
  state,
} from "../../../services/data_service.js";
import { required } from "vuelidate/lib/validators";

export default {
  name: "CostEstimateCostSlider",
  components: {},
  data() {
    return {
      isSwitching: false,
      isLoading: false,
      submitted: false,
      displayOptionIcon: null,
      costOptions: [],
      cost: {
        id: null,
        item: null,
        quantity: 0,
        cost_name: null,
        currency_name: null,
        markup_method: null,
        supplier: null,
        exists: true,
        description: null,
        type: "cost_type",
        options: [],
      },
    };
  },
  validations: {
    cost: {
      item: {
        required,
      },
      markup_method: {
        required,
      },
      description: {
        required,
      },
      supplier: {
        required,
      },
    },
  },
  async mounted() {
    await this.loadData();
    if (this.selectedObject.item) {
      this.cost = JSON.parse(JSON.stringify(this.selectedObject.item));
      this.cost.currency = this.selectedObject.estimate.client.currency_uid;
      this.cost.supplier = this.cost.item.supplier;
      this.cost.description = this.cost.item.description;
      this.costOptions = this.cost.item.options;
      this.cost.markup_method = this.cost.item.markup_method.name;
      this.cost.currency_name = this.cost.item.currency.name;
      if (this.costOptions.length == 0) {
        this.addOption();
        this.costOptions = this.cost.item.options;
      } else {
        this.costOptions.map((m) => {
          m.units = parseFloat(m.quantity);
          m.unit_cost = parseFloat(m.unit_price);
          m.markup = parseFloat(m.margin);
          m.resell_amount = parseFloat(m.price);
        });
      }

      this.cost.cost_name = this.cost.item.type_name;

      this.cost.item = this.cost_types.find((a) =>
        this.cost.item.type_name.includes(a.type_name)
      );
      this.cost.cost_name = this.cost.cost_name.replace(
        this.cost.item.type_name + " - ",
        ""
      );

      if (this.cost.cost_name === this.cost.item.type_name) {
        this.cost.cost_name = null;
      }
      if (this.cost.item && this.cost.item.currency) {
        fetchCurrencyConversion(
          1,
          this.cost.item.currency.name,
          this.currencyName
        );
      }
    }
  },

  beforeMount() {
    if (this.costOptions.length === 0) {
      this.addOption();
    }
  },

  methods: {
    handleKeyPress(event) {
      const editor = this.$refs.editor;
      if (editor && editor.$el.contains(event.target)) {
        // If "Enter" is pressed inside the editor, create a new line
        event.stopPropagation();
      } else {
        // Else, submit the form
        this.submitHandler();
      }
    },
    async loadData() {
      this.isLoading = true;
      await Promise.all([
        fetchAccountCostTypes(1),
        // fetchTaskTypePricing(),
        fetchBillingTiers(),
        fetchAccountSuppliers(),
        fetchCurrency(),
      ]);
      this.isLoading = false;
    },
    addOption() {
      const id = this.uuidv4();
      const option = {
        id: id,
        units: 1,
        unit_cost: 0,
        markup: 0,
        resell_amount: 0,
      };
      if (this.cost.item) {
        if (this.cost.markup_method === "Fixed price") {
          option.markup = parseFloat(
            (this.cost.item.default_markup * this.currencyConversion).toFixed(2)
          );
        } else {
          option.markup = parseFloat(this.cost.item.default_markup);
        }
        option.resell_amount = parseFloat(
          (this.cost.item.resell_amount * this.currencyConversion).toFixed(2)
        );
        option.unit_cost = parseFloat(
          (this.cost.item.default_cost_price * this.currencyConversion).toFixed(
            2
          )
        );
      }
      this.costOptions.push(option);
    },
    deleteOption(id) {
      if (this.costOptions.length <= 1) return;
      this.$confirm.require({
        message: "Are you sure you want to delete this option?",
        header: "Confirm Delete",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          if (this.estimate.status == "Draft" && this.costOptions.length > 1) {
            for (var index = 0; index < this.costOptions.length; index++) {
              const element = this.costOptions[index];
              if (element.uid == id) {
                this.costOptions.splice(index, 1);
              }
            }
          }
        },
        reject: () => {
          //callback to execute when user rejects the action
        },
      });
    },
    async handleCostSelect(cost) {
      if (cost) {
        await fetchCurrencyConversion(
          1,
          this.cost.item.currency.name,
          this.currencyName
        );

        this.cost.item.currency = cost.currency;
        this.cost.currency = cost.currency;
        this.cost.description = cost.description;
        this.cost.supplier = cost.supplier;
        this.cost.markup_method = cost.markup_method.name;
        this.costOptions.map((option) => {
          if (option) {
            if (this.cost.markup_method === "Fixed price") {
              option.markup = parseFloat(
                (cost.default_markup * this.currencyConversion).toFixed(2)
              );
            } else {
              option.markup = parseFloat(cost.default_markup);
            }

            option.resell_amount = parseFloat(
              (cost.resell_amount * this.currencyConversion).toFixed(6)
            );
            option.unit_cost = parseFloat(
              (cost.default_cost_price * this.currencyConversion).toFixed(6)
            );
          }
        });
      }
    },
    handleChangeResellAmount(option) {
      if (this.cost.item) {
        switch (this.cost.item.pricing_structure.name) {
          case "Rate per hour":
          case "Fixed price":
          case "Percentage":
            switch (this.cost.item.markup_method.name) {
              case "Rate per hour":
              case "Fixed price":
                return (option.resell_amount =
                  parseFloat(option.unit_cost) + parseFloat(option.markup));
              case "Percentage":
                var markup = 1 + option.markup / 100;
                return (option.resell_amount =
                  Number(markup) * Number(option.unit_cost));
            }
        }
      }
      return 0;
    },
    mouseenter(id) {
      this.displayOptionIcon = id;
    },
    mouseleave() {
      this.displayOptionIcon = null;
    },
    submitHandler() {
      var id = this.uuidv4();
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$toast.add({
          severity: "error",
          summary: "Please complete all the fields",
          life: 3000,
        });
      } else {
        this.cost.id = id;
        this.cost.new = true;
        // this.cost.item.currency =
        //   this.selectedObject.estimate.client.currency_uid;
        this.cost.item.description = this.cost.description;
        this.costOptions.map((m) => {
          m.quantity = parseFloat(m.units);
          m.unit_price = parseFloat(m.unit_cost);
          m.margin = parseFloat(m.markup);
          m.price = parseFloat(m.resell_amount);
        });
        this.cost.item.options = this.costOptions;
        if (this.selectedObject.item) {
          this.cost.item.uid = this.selectedObject.item.item.uid;
        }
        if (this.cost.cost_name) {
          this.cost.item.type_name += " - " + this.cost.cost_name;
        }

        if (!this.selectedObject.item) {
          delete this.cost.item.uid;
          delete this.cost.uid;
          this.cost.item.options.forEach((o) => {
            delete o.uid;
          });
          this.group.items.push(this.cost);
        } else {
          for (var index = 0; index < this.group.items.length; index++) {
            if (this.group.items[index].uid === this.cost.uid) {
              this.$set(this.group.items, index, this.cost);
            }
          }
        }
        this.$root.$emit("saveCostEstimate");
        this.clearSliderContent();
      }
    },
  },
  computed: {
    setSuffix() {
      let suffix = null;
      if (this.cost.markup_method === "Percentage") {
        suffix = "%";
      } else {
        suffix = null;
      }
      return suffix;
    },
    currencyConversion() {
      return state.currencyConversion;
    },
    clientCurrency() {
      if (this.currencies) {
        return this.currencies.find(
          (a) => a.uid === this.estimate.client.currency_uid
        );
      }
      return null;
    },
    currencySymbol() {
      return this.clientCurrency ? this.clientCurrency.description : null;
    },
    currencyName() {
      return this.clientCurrency ? this.clientCurrency.name : null;
    },
    currencies() {
      return state.currencies;
    },
    selectedObject() {
      return this.$store.getters.selectedObject;
    },
    estimate() {
      return this.selectedObject.estimate;
    },
    group() {
      return this.selectedObject.group;
    },
    cost_types() {
      if (state.costTypes) {
        state.costTypes.forEach((c) => {
          c.resell_amount = parseFloat(c.resell_amount);
        });
        return state.costTypes.filter((cost) => cost.active == 1);
      }
      return [];
    },
    billingTiers() {
      return state.billingTiers;
    },
    taskTypePricing() {
      return state.taskTypePricing;
    },
    accountSuppliers() {
      return state.accountSuppliers.filter((supplier) => supplier.active == 1);
    },
  },
};
</script>
    <style lang="scss" scoped>
</style>